import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./styles.module.scss";
import Loader from "../../components/loading/Loader";
import ShowToast from "../../components/ShowToast";
import Header from "../../components/header";
import BackIcon from "@mui/icons-material/West";
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
} from "@mui/material";
import TextFieldView from "../../components/TextFieldView";
import { BlueBgButton, WhiteBgButton } from "../../components/buttons";
import AdminApi from "../../api/AdminApi";
import { getGeographyArr, getIndustryArr } from "../../utills/TickerListHelper";
import { etfList, stockList } from "../../utills";

const TickerListDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentId, setCurrentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageCondition, setPageCondition] = useState("add");
  const [toast, showTost] = useState({});
  const [status, setStatus] = useState(true);
  const [tickerName, setTickerName] = useState("");
  const [tickerType, setTickerType] = useState(null);
  const [assetClass, setAssetClass] = useState("");
  const [geographyData, setgeographyData] = useState({});
  const [industryData, setIndustryData] = useState({});

  useEffect(() => {
    if (id === "add-ticker") {
      setgeographyData(getGeographyArr());
      setIndustryData(getIndustryArr());
      setPageCondition("add");
    } else {
      fetchData();
      setPageCondition("details");
    }
  }, [id]);

  const fetchData = async () => {
    setLoading(true);
    const res = await AdminApi.getTickerListDetails(id);
    if (res?.status === 200 && res?.data?.data?.[0]) {
      const response = await res?.data?.data?.[0];
      setgeographyData(getGeographyArr(response));
      setIndustryData(getIndustryArr(response));
      setTickerType(
        response?.is_stock ? "Stock" : response?.is_etf ? "ETF" : null
      );
      setTickerName(response?.name ?? "");
      setStatus(response?.status ?? true);
      setAssetClass(response?.asset_class);
      setCurrentId(response?._id);
      setLoading(false);
    } else {
      setLoading(false);
      navigate(-1);
    }
  };

  const handleGeographyData = async (val, label) => {
    while (val.charAt(0) === "0" && val.length > 1) {
      val = await val.substring(1);
    }
    let cloneData = { ...geographyData };
    cloneData[label] = Number(val);
    setgeographyData(cloneData);
  };

  const handleIndustryData = async (val, label) => {
    while (val.charAt(0) === "0" && val.length > 1) {
      val = await val.substring(1);
    }
    let cloneData = { ...industryData };
    cloneData[label] = Number(val);
    setIndustryData(cloneData);
  };

  const submitData = async () => {
    if (tickerType === null || tickerType === undefined || tickerType === "") {
      showTost({
        show: true,
        text: "Please select the Ticker Type!",
        type: "error",
      });
      return;
    }
    if (assetClass === "" || assetClass === undefined || assetClass === null) {
      showTost({
        show: true,
        text: "Please select the Asset Class!",
        type: "error",
      });
      return;
    }
    if (tickerName === "" || tickerName === undefined || tickerName === null) {
      showTost({
        show: true,
        text: "Please enter the Ticker Name!",
        type: "error",
      });
      return;
    }
    setLoading(true);
    const payload = {
      name: tickerName,
      asset_class: assetClass,
      country_weighting: geographyData,
      sector_weighting: industryData,
      is_stock: tickerType === "Stock" ? true : false,
      is_etf: tickerType === "ETF" ? true : false,
      status: status,
    };
    try {
      const res = currentId
        ? await AdminApi.updateTickerList(currentId, payload)
        : await AdminApi.setTickerList(payload);
      if (res?.status === 200 || res?.status === 201) {
        setLoading(false);
        showTost({
          show: true,
          text: `Ticker is ${currentId ? "updated" : "addedd"} successfully`,
          type: "success",
        });
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      showTost({
        show: true,
        text:
          e?.response?.data?.message?.asset_class?.[0] ??
          e?.response?.data?.message ??
          "Error! Please try again or later.",
        type: "error",
      });
    }
  };

  return (
    <div className={styles.container}>
      {loading && <Loader />}
      <ShowToast toast={toast} showTost={showTost} />
      <Header />
      <Stack direction="row" style={{ alignItems: "center", marginTop: 20 }}>
        <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
          <BackIcon />
        </div>
        <p className={styles.back}>Back To Ticker List</p>
      </Stack>

      {pageCondition !== "add" && <p className={styles.details}>Details</p>}
      {pageCondition === "add" && (
        <p className={styles.details}>Add New ETF & Stock </p>
      )}

      <p className={styles.title}>Is Published</p>
      <div style={{ marginLeft: -10, marginTop: -10 }}>
        <Switch
          checked={status}
          onChange={() => setStatus(!status)}
          inputProps={{ "aria-label": "controlled" }}
          disabled={pageCondition === "details" ? true : false}
        />
      </div>

      <p className={styles.title}>Ticker Type</p>
      <FormControl style={{ marginTop: -10 }}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={tickerType}
          onChange={(e) => {
            setAssetClass("");
            setTickerType(e.target.value);
          }}
        >
          <FormControlLabel
            value="ETF"
            control={<Radio />}
            label="ETF"
            style={{ paddingRight: 20 }}
            disabled={pageCondition === "details" ? true : false}
          />
          <FormControlLabel
            value="Stock"
            control={<Radio />}
            label="Stock"
            disabled={pageCondition === "details" ? true : false}
          />
        </RadioGroup>
      </FormControl>

      <p className={styles.title}>Asset Class</p>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 12, md: 24 }}
        style={{ alignItems: "center" }}
      >
        <Grid item xs={12} sm={8} md={8}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select Asset Class
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Select Asset Class"
              value={assetClass}
              onChange={(e) => setAssetClass(e.target.value)}
              disabled={pageCondition === "details" ? true : false}
            >
              {tickerType === "ETF" &&
                etfList.map((item, i) => (
                  <MenuItem value={item.value} key={i}>
                    {item.label}
                  </MenuItem>
                ))}
              {tickerType === "Stock" &&
                stockList.map((item, i) => (
                  <MenuItem value={item.value} key={i}>
                    {item.label}
                  </MenuItem>
                ))}
              {(tickerType === "" ||
                tickerType === null ||
                tickerType === undefined) && (
                <MenuItem value={null} disabled>
                  Please select the Ticker Type
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <p className={styles.title}>Ticker Name</p>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 12, md: 24 }}
        style={{ alignItems: "center" }}
      >
        <Grid item xs={12} sm={8} md={8}>
          <TextFieldView
            type="text"
            label={"Ticker"}
            value={tickerName}
            setValue={setTickerName}
            disabled={pageCondition === "details" ? true : false}
          />
        </Grid>
      </Grid>

      <p className={styles.title}>Geography</p>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 12, md: 24 }}
        style={{ alignItems: "center" }}
      >
        {Object.entries(geographyData).map(([x, y], i) => (
          <Grid item xs={12} sm={8} md={8} key={i}>
            <TextFieldView
              type="number"
              label={x}
              value={y}
              setValue={handleGeographyData}
              disabled={pageCondition === "details" ? true : false}
            />
          </Grid>
        ))}
      </Grid>

      <p className={styles.title}>Industry</p>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 12, md: 24 }}
        style={{ alignItems: "center" }}
      >
        {Object.entries(industryData).map(([x, y], i) => (
          <Grid item xs={12} sm={8} md={8} key={i}>
            <TextFieldView
              type="number"
              label={x}
              value={y}
              setValue={handleIndustryData}
              disabled={pageCondition === "details" ? true : false}
            />
          </Grid>
        ))}
      </Grid>

      {pageCondition === "details" ? (
        <WhiteBgButton
          title={"Edit"}
          onClick={() => setPageCondition("update")}
          customStyle={{
            marginTop: 30,
            color: "#3f8ea3",
            borderColor: "#3F8EA3",
          }}
        />
      ) : (
        <BlueBgButton
          title={pageCondition === "add" ? "Add New" : "Update"}
          onClick={() => submitData()}
          customStyle={{ marginTop: 30 }}
        />
      )}
    </div>
  );
};

export default TickerListDetails;
