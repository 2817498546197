export * from "./AllocationOptions";
export * from "./AmericanArr";
export * from "./CanadaAndDualCitizenArr";
export * from "./FormsData";
export * from "./SubmitFormsHandle";
export * from "./ChildIsAmericanArr";
export * from "./ChildIsCanadaOrDual";
export * from "./Cache";
export * from "./CalculatorAndGraph";
export * from "./ColorsPlate";

export const getColors = (i) => {
  let color;
  switch (i) {
    case 0:
      color = "#FDEEEF";
      break;
    case 1:
      color = "#E8F8ED";
      break;
    case 2:
      color = "#F3ECF9";
      break;
    case 3:
      color = "#FEF5E8";
      break;
    case 4:
      color = "#E7FAFF";
      break;
    default:
      color = "#E7FFE7";
  }
  return color;
};

export const etfList = [
  {
    value: "Equity",
    label: "Equity",
  },
  {
    value: "Bond",
    label: "Bond",
  },
  {
    value: "-",
    label: "-",
  },
  {
    value: "Real-Estate",
    label: "Real-Estate",
  },
  {
    value: "Mixed",
    label: "Mixed",
  },
  {
    value: "Gold",
    label: "Gold",
  },
  {
    value: "Cash",
    label: "Cash",
  },
];

export const stockList = [
  {
    value: "US-Stock",
    label: "US-Stock",
  },
  {
    value: "CANADA-Stock",
    label: "CANADA-Stock",
  },
];
